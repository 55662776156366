@font-face {
  font-family: "Intro";
  src: url("/fonts/Intro_Book-webfont.ttf");
  src: url("/fonts/Intro_Book-webfont.woff2") format("woff2"),
    url("/fonts/Intro_Book-webfont.woff") format("woff"),
    url("/fonts/Intro_Book-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Intro";
  src: url("/fonts/Intro_Book_Italic-webfont.ttf");
  src: url("/fonts/Intro_Book_Italic-webfont.woff2") format("woff2"),
    url("/fonts/Intro_Book_Italic-webfont.woff") format("woff"),
    url("/fonts/Intro_Book_Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Intro";
  src: url("/fonts/Intro_Bold-webfont.ttf");
  src: url("/fonts/Intro_Bold-webfont.woff2") format("woff2"),
    url("/fonts/Intro_Bold-webfont.woff") format("woff"),
    url("/fonts/Intro_Bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Intro";
  src: url("/fonts/Intro_Bold_Italic-webfont.ttf");
  src: url("/fonts/Intro_Bold_Italic-webfont.woff2") format("woff2"),
    url("/fonts/Intro_Bold_Italic-webfont.woff") format("woff"),
    url("/fonts/Intro_Bold_Italic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Intro";
  src: url("/fonts/Intro_Black-webfont.ttf");
  src: url("/fonts/Intro_Black-webfont.woff2") format("woff2"),
    url("/fonts/Intro_Black-webfont.woff") format("woff"),
    url("/fonts/Intro_Black-webfont.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.iframe-height-restricted {
  width: 100% !important;
  max-height: 380px !important;
}

@media (max-width: 480px) {
  .iframe-height-restricted {
    width: 100% !important;
    max-height: 200px !important;
  }
}

.sanitized p,
.sanitized span {
  font-family: inherit !important;
  line-height: normal !important;
  color: white !important;
  background-color: transparent !important;
}

.sanitized ol,
ul {
  margin: revert !important;
  padding: revert !important;
}

.sanitized a {
  text-decoration: underline;
  color: #fddf02 !important;
}

.sanitized iframe {
  width: 100% !important;
}

.sanitized .button {
  background: #fddf02 !important;
  color: black !important;
  padding: 10px 20px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  text-decoration: none !important;
  margin: 5px 5px !important;
}

.sanitized p:has(.button) {
  display: flex !important;
  flex-wrap: wrap !important;
}
